import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { RxjsComponent } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { NpsReviewsFilterService } from '../../services';
import { onlyVerifiedOption } from './reviews-only-verified.constants';

@Component({
    selector: 'smvd-ui-reviews-only-verified',
    templateUrl: './reviews-only-verified.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsOnlyVerifiedComponent extends RxjsComponent implements OnInit {
    public readonly onlyVerifiedOption = onlyVerifiedOption;
    public readonly control = this.formBuilder.control(false);

    constructor(public readonly filterService: NpsReviewsFilterService, private readonly formBuilder: UntypedFormBuilder) {
        super();
    }

    public ngOnInit(): void {
        this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            this.filterService.filterOnlyVerified(value);
        });
    }
}
