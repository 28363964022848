import { AfterViewInit, Component, forwardRef, Host, Input, OnChanges, OnInit, Optional, SimpleChanges, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, SimpleChangesUtils, UiUtils } from '@smooved/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UiIconSize } from '../../icon';
import { autoWidth, nonBreakingSpace } from '../../ui.constant';
import { UiHeaderVariant } from '../../ui.enums';
import { BaseInput } from '../base-input';
import { DropdownInput } from './dropdown-input';
import { DropdownInputAppearance } from './dropdown-input-appearance.enum';

@Component({
    selector: 'ui-dropdown-input, smvd-ui-dropdown-input',
    template: `
        <app-label-container
            [required]="required"
            [muted]="labelMuted"
            [id]="id"
            [label]="label"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
            [bold]="labelBold"
            [showRequiredAsterisk]="showRequiredAsterisk"
        >
            <mat-form-field
                *ngIf="custom"
                floatLabel="never"
                [appearance]="appearance"
                [ngClass]="'ui-dropdown-input-appearance-' + appearance"
            >
                <mat-select
                    [disableRipple]="true"
                    [multiple]="multiple"
                    [id]="id"
                    [disabled]="innerDisabled"
                    [errorStateMatcher]="errorStateMatcher"
                    [(ngModel)]="innerModel"
                    (blur)="onBlur($event)"
                    (ngModelChange)="onModelChange()"
                    [placeholder]="placeholder"
                >
                    <mat-select-trigger>
                        <div *ngIf="variant === uiHeaderVariant.Svg; else selectedTrigger">
                            <app-svg-illustration
                                [svg]="currentOption?.svg?.path"
                                [style.width]="currentOption?.svg?.width"
                                [style.height]="currentOption?.svg?.height"
                            ></app-svg-illustration>
                        </div>
                        <ng-template #selectedTrigger>
                            <div [innerHtml]="selectTriggerHtml"></div>
                        </ng-template>
                    </mat-select-trigger>
                    <mat-option
                        *ngFor="let option of innerOptions; trackBy: trackByLabel"
                        [disabled]="!!optionDisabled$ && (optionDisabled$(option) | async)"
                        [value]="option.value"
                        [attr.data-testid]="option.id"
                    >
                        <app-svg-illustration
                            *ngIf="option?.svg"
                            [svg]="option.svg.path"
                            [style.width]="option.svg.width"
                            [style.height]="option.svg.height"
                        ></app-svg-illustration>
                        <span *ngIf="option.label || option.labelResource">{{ option.label || (option.labelResource | translate) }}</span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstErrorState() | errorState }}</mat-error>
            </mat-form-field>

            <div *ngIf="!custom">
                <select
                    [id]="id"
                    [disabled]="innerDisabled"
                    matNativeControl
                    [errorStateMatcher]="errorStateMatcher"
                    [(ngModel)]="innerModel"
                    (blur)="onBlur($event)"
                    (ngModelChange)="onModelChange()"
                >
                    <option *ngFor="let option of innerOptions; trackBy: trackByLabel" [value]="option.value">
                        <app-svg-illustration
                            *ngIf="option?.svg"
                            [svg]="option.svg.path"
                            [style.width]="option.svg.width"
                            [style.height]="option.svg.height"
                        ></app-svg-illustration>
                        <span *ngIf="option.label || option.labelResource">{{ option.label || (option.labelResource | translate) }}</span>
                    </option>
                </select>
                <mat-error *ngIf="getAbstractControl()?.invalid">{{ getFirstErrorState() | errorState }}</mat-error>
            </div>
        </app-label-container>
    `,
    styleUrls: ['./dropdown-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownInputComponent),
            multi: true,
        },
    ],
})
export class DropdownInputComponent<T> extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public subLabel: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public labelBold = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public options: DropdownInput<T>[];
    @Input() public custom = true;
    @Input() public labelMuted: boolean;
    @Input() public optionDisabled$: (option: DropdownInput<T>) => Observable<boolean>;
    @Input() public appearance = DropdownInputAppearance.Outline;
    @Input() public multiple = false;
    @Input() public variant = UiHeaderVariant.Span;
    @Input() public iconSize = UiIconSize.Xm;
    @Input() public required: boolean;

    public selectTriggerHtml: string;
    public innerModel: T;
    public innerOptions: DropdownInput<T>[];
    public currentOption: DropdownInput<T>;

    public readonly uiHeaderVariant = UiHeaderVariant;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        public readonly translateService: TranslateService
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        if (this.appearance === DropdownInputAppearance.Link) this.width = autoWidth;
        this.getSelectedOption(this.innerModel);
        this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.setValue(this.innerModel));
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngOnChanges({ options }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(options)) {
            this.innerOptions = this.options;
            this.getSelectedOption(this.innerModel);
        }
    }

    public writeValue(value: T): void {
        this.setValue(value);
    }

    public onModelChange(): void {
        this.getSelectedOption(this.innerModel);
        this.propagateChange(this.innerModel);
    }

    public trackByLabel(index: number, option: DropdownInput<T>): string | number {
        return option.label || option.labelResource || index;
    }

    private setValue(value: T): void {
        this.getSelectedOption(value);
        this.innerModel = value;
    }

    private getSelectedOption(model): void {
        if (ArrayUtils.isEmpty(this.options)) return;
        if (Array.isArray(model)) {
            this.selectTriggerHtml = model.map((item) => this.setVisibleLabel(item)).join('<span>,</span>');
            return;
        }
        this.selectTriggerHtml = this.setVisibleLabel(model);
    }

    private setVisibleLabel(model): string {
        const currentOption = this.options.find(({ value }) => value === model);
        if (!currentOption) return this.placeholder;
        if (this.variant === UiHeaderVariant.Svg) {
            this.currentOption = currentOption;
            return this.placeholder;
        }
        const variant = this.variant || UiHeaderVariant.Span;
        const label =
            currentOption?.label ||
            (currentOption?.labelResource && (this.translateService.instant(currentOption?.labelResource) as string)) ||
            nonBreakingSpace;
        return UiUtils.createVariant(variant, label);
    }
}
