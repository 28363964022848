<app-label-container
    [required]="required"
    [id]="(id || option?.id) + '-input'"
    [label]="label"
    [labelTemplateRef]="labelTemplateRef"
    [hasMargin]="hasMargin"
    [hasMarginDouble]="hasMarginDouble"
    [uiDirection]="direction"
    [uiLabelPosition]="labelPosition"
>
    <label class="__input-container" *ngIf="custom" [ngClass]="{ '__input-container--ignore-min-width': ignoreMinWidth }">
        <input
            class="__input"
            type="checkbox"
            [(ngModel)]="innerModel"
            (ngModelChange)="onModelChange()"
            [value]="option?.value"
            [name]="option?.name"
            [disabled]="innerDisabled"
        />
        <div class="__container u-flex-align-items-center u-flex-column u-padding">
            <img *ngIf="option?.icon" class="__icon u-margin-bottom-half" [src]="'assets/images/' + option?.icon" />
            <app-svg *ngIf="option?.svg" [name]="option?.svg" class="__svg u-margin-bottom-half"></app-svg>
            <div class="u-flex-column u-flex-align-items-start">
                <div *ngIf="optionLabelElement" class="__label" [class.u-font-size-small]="small" [innerHtml]="optionLabelElement"></div>
                <span class="u-color-muted u-font-size-small" [innerHTML]="option?.description" *ngIf="option?.description"></span>
                <span
                    class="u-color-muted u-font-size-small"
                    [innerHTML]="option.descriptionResource | translate"
                    *ngIf="option?.descriptionResource"
                ></span>
            </div>
        </div>
    </label>
    <mat-checkbox
        [class.__horizontal]="direction === uiDirections.Horizontal"
        *ngIf="!custom"
        [disabled]="innerDisabled"
        [id]="id || option?.id"
        [(ngModel)]="innerModel"
        (ngModelChange)="onModelChange()"
        [value]="option?.value"
        [name]="option?.name"
    >
        <div class="u-flex-column u-flex-align-items-start">
            <div *ngIf="optionLabelElement" class="__label" [class.u-font-size-small]="small" [innerHtml]="optionLabelElement | sanitize: 'html'"></div>
            <span class="u-color-muted u-font-size-small" [innerHTML]="option?.description" *ngIf="option?.description"></span>
            <span
                class="u-color-muted u-font-size-small"
                [innerHTML]="option.descriptionResource | translate"
                *ngIf="option?.descriptionResource"
            ></span>
        </div>
        <ng-content></ng-content>
    </mat-checkbox>
    <mat-error *ngIf="isErrorState()">{{ getFirstErrorState() | errorState }}</mat-error>
</app-label-container>
