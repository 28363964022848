<app-detail>
    <ng-container header-extra-options *ngIf="!!move">
        <app-impersonate [move]="move" returnUrl="/" class="u-padding-left" [disabled]="!move.isDraft"></app-impersonate>
        <button
            app-button
            icon="lock_open"
            [context]="uiContext.Secondary"
            [appearance]="buttonAppearance.Icon"
            [iconSize]="iconSize.Md"
            [title]="'ADMIN.DASHBOARD.DETAIL.REACTIVATE' | translate"
            [class.u-disabled]="move.isDraft"
            (click)="activateMove(move)"
        >
            {{'ADMIN.DASHBOARD.DETAIL.REACTIVATE' | translate}}
        </button>
        <button
            app-button
            icon="delete"
            [context]="uiContext.Warning"
            [appearance]="buttonAppearance.Icon"
            [iconSize]="iconSize.Md"
            [title]="'COMMON.DELETE' | translate"
            [class.u-disabled]="!move.canDelete"
            (click)="showDeleteModal(move)"
        >
            {{'COMMON.DELETE' | translate}}
        </button>
    </ng-container>
    <div ngProjectAs="detail" *ngIf="!!move">
        <div class="__data">
            <div class="u-flex-column u-flex-align-items-start">
                <app-transferee-detail
                    class="u-display-block u-margin-bottom-triple"
                    [label]="move.user?.role !== 'leaver' ? 'MOVE.MOVER_ROLE.TRANSFEREE' : 'MOVE.MOVER_ROLE.LEAVER'"
                    [move]="move"
                    (moveChange)="detail.updateMove($event, true)"
                ></app-transferee-detail>
                <app-real-estate-agent-detail [move]="move" [showPrice]="true" [canEdit]="true"></app-real-estate-agent-detail>
            </div>

            <div class="u-flex-column u-flex-align-items-start">
                <app-current-address-detail class="u-display-block u-margin-bottom-triple" [move]="move"></app-current-address-detail>

                <app-moving-address-detail class="u-display-block u-margin-bottom-triple" [move]="move"></app-moving-address-detail>

                <app-contact-address-detail
                    class="u-display-block u-margin-bottom-triple"
                    *ngIf="showContactAddress$ | async"
                    [move]="move"
                ></app-contact-address-detail>

                <app-moving-date-detail class="u-display-block u-margin-bottom-triple" [move]="move"></app-moving-date-detail>

                <ng-container [ngSwitch]="!!move.linkedMove">
                    <h6 class="u-margin-bottom-half u-color-muted">
                        {{ (move.user?.role !== 'leaver' ? 'MOVE.MOVER_ROLE.LEAVER' : 'MOVE.MOVER_ROLE.TRANSFEREE') | translate }}
                    </h6>
                    <a *ngSwitchCase="true" class="u-link" (click)="showLinkedMove()"
                        >{{ 'MOVE.MOVER_ROLE.' + move.linkedMove?.user?.role?.toUpperCase() + '.VIEW_DETAILS' | translate }}</a
                    >
                    <span *ngSwitchCase="false" class="u-color-muted">-</span>
                </ng-container>
            </div>

            <div class="u-flex-column u-flex-align-items-start">
                <app-person-extra-details [user]="move.user" class="u-w100p u-margin-bottom-triple"></app-person-extra-details>
                <app-move-remark-detail [move]="move" class="u-w100p u-margin-bottom-triple"></app-move-remark-detail>
                <app-services-interest [move]="move"></app-services-interest>
            </div>
        </div>
    </div>

    <div ngProjectAs="interests" *ngIf="!!move">
        <button mat-button [matMenuTriggerFor]="servicesMenu" [disableRipple]="true" [disabled]="!(services$ | async)?.length">
            {{ 'ADD' | translate }}
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu xPosition="before" #servicesMenu="matMenu">
            <button *ngFor="let service of services$ | async" mat-menu-item (click)="setInterest(service)">
                {{ 'REAL_ESTATE_AGENT.EOTS.OTHER_SERVICES.OPTIONS.' + service.toUpperCase() | translate }}
            </button>
        </mat-menu>
    </div>

    <div ngProjectAs="services" *ngIf="!!move">
        <ng-container *ngIf="move.state !== moveState.Inactive">
            <ng-container *ngIf="move.user.role === moverRole.Leaver">
                <h6 class="u-margin-bottom-half">{{ 'MOVE.ENERGY_STOP' | translate }}</h6>
                <div class="__energy-stop-container u-margin-bottom-triple" *ngIf="move.interested?.energyStop; else noEnergyStopInterest">
                    <div>
                        <app-admin-todo-leaver-suppliers-received todo [move]="move"></app-admin-todo-leaver-suppliers-received>
                        <app-admin-todo-energy-stop-meter-readings-completed
                            todo
                            [move]="move"
                        ></app-admin-todo-energy-stop-meter-readings-completed>
                        <app-admin-todo-leaver-regularization-bill-requested
                            todo
                            [move]="move"
                        ></app-admin-todo-leaver-regularization-bill-requested>
                        <a
                            smvd-ui-button
                            [appearance]="buttonAppearance.Link"
                            class="u-padding-none"
                            (click)="onNotInterested('energyStop')"
                            >{{ 'MOVE.NOT_INTERESTED' | translate }}</a
                        >
                    </div>
                    <app-energy-stop-info-detail [move]="move"></app-energy-stop-info-detail>
                </div>
                <ng-template #noEnergyStopInterest>
                    <div class="u-margin-bottom-triple u-color-muted">{{ 'MOVE.NOT_INTERESTED' | translate }}</div>
                </ng-template>
            </ng-container>

            <div class="u-margin-bottom-triple">
                <div class="u-flex-row u-flex-align-items-center u-margin-bottom-half">
                    <h6 class="u-margin-right">{{ 'ENERGY.LABEL' | translate }}</h6>
                    <ui-dropdown-menu-button
                        [label]="'EAN.SEARCH' | translate"
                        [context]="uiContext.Secondary"
                        [size]="buttonSize.Xs"
                        [appearance]="buttonAppearance.Stroked"
                        class="u-margin-right-half"
                    >
                        <button mat-menu-item uiDropdownMenuItem (click)="getEanByAddress()">{{ 'EAN.BY_ADDRESS' | translate }}</button>
                        <button mat-menu-item uiDropdownMenuItem (click)="getEanByMeterNumbers()">
                            {{ 'EAN.BY_METER_NUMBER' | translate }}
                        </button>
                    </ui-dropdown-menu-button>

                    <button
                        *ngIf="move?.energyOrderedByAdmin"
                        app-button
                        [context]="uiContext.Secondary"
                        [size]="buttonSize.Xs"
                        [appearance]="buttonAppearance.Stroked"
                        (click)="getEnergyOrderStatus()"
                    >
                        {{'COMMON.STATUS' | translate}}
                    </button>
                </div>
                <div
                    *ngIf="!!move.interested?.energy || !!move.energyOrdered; else noEnergyInterest"
                    class="u-flex-row u-flex-justify-content-space-between"
                >
                    <div>
                        <app-energy-offer-suggestion [move]="move" class="u-margin-bottom u-display-block"></app-energy-offer-suggestion>
                        <app-energy-ordered [move]="move" class="u-margin-bottom u-display-block"></app-energy-ordered>
                        <app-energy-ordered-by-admin [move]="move" class="u-margin-bottom u-display-block"></app-energy-ordered-by-admin>
                        <app-energy-advance-amount [move]="move" class="u-margin-bottom u-display-block"></app-energy-advance-amount>
                        <app-service-meter-readings class="u-margin-bottom u-display-block" [move]="move"></app-service-meter-readings>
                        <app-energy-documents-moving-address class="u-margin-bottom u-display-block" [move]="move">
                        </app-energy-documents-moving-address>

                        <a class="u-link" *ngIf="!!move.interested?.energy" (click)="onNotInterested('energy')"
                            >{{ 'MOVE.NOT_INTERESTED' | translate }}</a
                        >
                    </div>
                    <app-energy-info-detail [move]="move"></app-energy-info-detail>
                </div>

                <ng-template #noEnergyInterest>
                    <p class="u-color-muted u-margin-bottom">{{ 'MOVE.NOT_INTERESTED' | translate }}</p>
                    <app-service-meter-readings class="u-margin-bottom u-display-block" [move]="move"></app-service-meter-readings>
                    <app-energy-documents-moving-address-without-contract class="u-margin-bottom u-display-block" [move]="move">
                    </app-energy-documents-moving-address-without-contract>
                </ng-template>
            </div>

            <div class="u-margin-bottom-half u-flex-row u-flex-align-items-center">
                <h6>{{ 'TELECOM.LABEL' | translate }}</h6>
                <app-note-for-smooved *ngIf="move.orderTelecom as order" [order]="order"></app-note-for-smooved>
            </div>
            <div *ngIf="!!move.interested?.telecom; else noTelecomInterest" class="__telecom-container">
                <div>
                    <app-telecom-offer-suggestion class="u-display-block u-margin-bottom" [move]="move"></app-telecom-offer-suggestion>
                    <app-telecom-ordered-by-mover class="u-display-block u-margin-bottom" [move]="move"></app-telecom-ordered-by-mover>
                    <app-telecom-ordered-by-admin class="u-display-block u-margin-bottom" [move]="move"></app-telecom-ordered-by-admin>
                    <app-telecom-installation-date-by-admin
                        class="u-display-block u-margin-bottom"
                        *ngIf="showTelecomInstallation$ | async"
                        [move]="move"
                    ></app-telecom-installation-date-by-admin>
                    <app-telecom-phone-transfer-by-admin class="u-display-block" *ngIf="showPhoneTransfer$ | async" [move]="move">
                    </app-telecom-phone-transfer-by-admin>
                    <a class="u-link" (click)="onNotInterested('telecom')">{{ 'MOVE.NOT_INTERESTED' | translate }}</a>
                </div>
                <app-telecom-info-detail [move]="move"></app-telecom-info-detail>
            </div>
            <ng-template #noTelecomInterest>
                <div class="u-margin-bottom-triple u-color-muted">{{ 'MOVE.NOT_INTERESTED' | translate }}</div>
            </ng-template>

            <div class="__water-move-detail-modal-title-and-toggle u-flex u-margin-bottom-half">
                <h6 class="u-margin-right-half">{{ 'WATER.LABEL' | translate }}</h6>
                <mat-slide-toggle
                    id="water-move-detail-modal-mat-slide-toggle"
                    [checked]="!move.waterCollectiveMeter"
                    (change)="toggleWaterTransfer(move.waterCollectiveMeter)"
                    color="primary"
                    class="u-margin-right-half __water-move-detail-modal-mat-slide-toggle"
                ></mat-slide-toggle>
            </div>

            <div class="__water-container" *ngIf="!move.waterCollectiveMeter; else waterCollectiveMeter">
                <div>
                    <app-water-transfer-documents [move]="move" class="u-display-block u-margin-bottom"></app-water-transfer-documents>
                    <smvd-app-water-meter-readings-by-admin
                        [move]="move"
                        (moveUpdated)="updateMoveAfterPatch($event)"
                        class="u-display-block u-margin-bottom"
                    ></smvd-app-water-meter-readings-by-admin>
                    <app-water-documents-moving-address-by-admin [move]="move"></app-water-documents-moving-address-by-admin>
                </div>
                <app-water-info-detail [move]="move"></app-water-info-detail>
            </div>
            <ng-template #waterCollectiveMeter>
                <p id="water-collective-meter-label" class="u-color-muted u-margin-bottom-triple">
                    {{ 'ADMIN.DASHBOARD.DETAIL.WATER.WATER_COLLECTIVE_METER' | translate }}
                </p>
            </ng-template>
        </ng-container>

        <h6 class="u-margin-bottom-half">{{ 'INSURANCES.LABEL' | translate }}</h6>
        <div class="__insurances-container">
            <app-move-detail-insurances [move]="move"></app-move-detail-insurances>
            <app-insurances-info-detail [move]="move"></app-insurances-info-detail>
        </div>

        <h6 class="u-margin-bottom-half">{{ 'MORTGAGE.LABEL' | translate }}</h6>
        <div class="__mortgage-container u-flex-column">
            <app-check-item class="u-margin-bottom-half" [checked]="!!move.orderMortgage">
                <ng-container ngProjectAs="checked">
                    {{ 'APPOINTMENT_SCHEDULED' | i18nKeyTranslate: appI18nKeyTypes.AdminMortgage }}
                </ng-container>
                <ng-container ngProjectAs="unchecked">
                    {{ 'APPOINTMENT_SCHEDULED' | i18nKeyTranslate: appI18nKeyTypes.AdminMortgage }}
                </ng-container>
            </app-check-item>
            <app-check-item class="u-margin-bottom-half" [checked]="!!move.orderMortgage?.appointmentCompleted?.value">
                <ng-container ngProjectAs="checked">
                    {{ 'APPOINTMENT_FINISHED' | i18nKeyTranslate: appI18nKeyTypes.AdminMortgage }}
                    <button
                        app-button
                        type="link"
                        appearance="link"
                        class="u-margin-left-half"
                        (click)="updatedAppointment(move.orderMortgage._id, false)"
                    >
                        {{ 'UNCHECK' | translate }}
                    </button>
                </ng-container>
                <ng-container ngProjectAs="unchecked">
                    <div class="u-flex-column u-flex-align-items-start">
                        <span class="u-margin-bottom-half"
                            >{{ 'APPOINTMENT_FINISHED' | i18nKeyTranslate: appI18nKeyTypes.AdminMortgage }}</span
                        >
                        <button
                            *ngIf="!!move.orderMortgage"
                            app-button
                            [context]="uiContext.Secondary"
                            [appearance]="buttonAppearance.Stroked"
                            (click)="updatedAppointment(move.orderMortgage._id, true)"
                        >
                            {{ 'CHECK' | translate }}
                        </button>
                    </div>
                </ng-container>
            </app-check-item>
        </div>

        <app-rental-inspection-details [move]="move" (reFetch)="detail.reload()"></app-rental-inspection-details>
    </div>

    <section ngProjectAs="contactLogs" class="u-margin-bottom-triple" *ngIf="!!move">
        <header
            class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-bordered-bottom u-padding-bottom-half u-margin-bottom"
        >
            <h5>{{ 'MOVE.CONTACT_LOGS.TITLE' | translate }}</h5>
            <button smvd-ui-button [appearance]="buttonAppearance.Stroked" [context]="uiContext.Secondary" (click)="createContactLog()">
                {{'NEW' | translate}}
            </button>
        </header>
        <app-contact-logs [move]="move"></app-contact-logs>
    </section>

    <div ngProjectAs="nps" *ngIf="!!move">
        <app-move-nps-detail [move]="move"></app-move-nps-detail>
        <smvd-app-review-history [move]="move"></smvd-app-review-history>
        <app-gift-track-and-trace
            class="u-margin-top-triple u-display-block"
            *ngIf="move.orders | orderGift as order"
            [order]="order"
            [isAdmin]="authenticationSandbox.isAdmin$ | async"
        ></app-gift-track-and-trace>
    </div>

    <section ngProjectAs="escalations" class="u-margin-bottom-triple" *ngIf="!!move">
        <header
            class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-bordered-bottom u-padding-bottom-half u-margin-bottom"
        >
            <h5>{{ 'TITLE_PLURAL' | i18nKeyTranslate: appI18nKeyTypes.Escalation }}</h5>
            <button smvd-ui-button [appearance]="buttonAppearance.Stroked" [context]="uiContext.Secondary" (click)="createEscalation()">
                {{'NEW' | translate}}
            </button>
        </header>
        <app-move-detail-escalations [move]="move"></app-move-detail-escalations>
    </section>
</app-detail>
