import { NgModule } from '@angular/core';
import { RentalInspectionAssetComponent } from '@app/document-center/components/rental-inspection-asset/rental-inspection-asset.component';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentCenterModule as UiDocumentCenterModule } from '@smooved/ui';
import { DocumentCenterProvidersModule } from './document-center-providers.module';

@NgModule({
    declarations: [RentalInspectionAssetComponent],
    imports: [DocumentCenterProvidersModule, UiDocumentCenterModule, TranslateModule],
    exports: [RentalInspectionAssetComponent],
})
export class DocumentCenterModule {}
