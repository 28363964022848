<form [formGroup]="form">
    <div class="u-flex-column">
        <app-check-input
            *ngIf="options.mover as option"
            [option]="option"
            class="u-margin-right-half u-margin-bottom-half"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [custom]="false"
            width="auto"
            [formControlName]="formControlNames.mover"
        >
        </app-check-input>

        <app-check-input
            *ngIf="options.realEstateAgent as option"
            [option]="option"
            class="u-margin-right-half u-margin-bottom-half"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [custom]="false"
            width="auto"
            [formControlName]="formControlNames.realEstateAgent"
        >
        </app-check-input>

        <app-check-input
            *ngIf="options.linkedMove as option"
            [option]="option"
            class="u-margin-right-half u-margin-bottom-half"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [custom]="false"
            width="auto"
            [formControlName]="formControlNames.linkedMove"
        >
        </app-check-input>
    </div>

    <div class="u-margin-bottom-half">
        <app-check-input
            [option]="options.supplierChecked"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [custom]="false"
            width="auto"
            [formControlName]="formControlNames.supplierChecked"
            #supplierChecked
        ></app-check-input>

        <div *ngIf="supplierChecked.checked" class="u-margin-top-half">
            <app-supplier-input
                [formControlName]="formControlNames.supplier"
                width="100%"
                [placeholder]="'MULTI_CHANNEL.SUPPLIER_EMAIL' | i18nKey: i18nKeyType.ContactLog | translate"
                [hasMarginDouble]="false"
                [hasMargin]="false"
            ></app-supplier-input>
        </div>
    </div>

    <div>
        <app-check-input
            [option]="options.otherChecked"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [custom]="false"
            width="auto"
            [formControlName]="formControlNames.otherChecked"
            #otherChecked
        ></app-check-input>

        <div *ngIf="otherChecked.checked" class="u-margin-top-half">
            <app-text-input
                [formControlName]="formControlNames.other"
                width="100%"
                [placeholder]="'MULTI_CHANNEL.OTHER_EMAIL' | i18nKey: i18nKeyType.ContactLog | translate"
                [hasMarginDouble]="false"
                [hasMargin]="false"
            ></app-text-input>
        </div>
    </div>
</form>
