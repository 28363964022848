import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
    SkipSelf,
    TemplateRef,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormControl, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SimpleChangesUtils } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { UiDirection, UiLabelPosition } from '../../ui.enums';
import { BaseInput } from '../base-input';
import { MyErrorStateMatcher } from '../error-state-matcher';
import { CheckInput } from './check-input';
import { defaultVariant } from './check-input.constants';

@Component({
    selector: 'app-check-input',
    templateUrl: './check-input.component.html',
    styleUrls: ['./check-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckInputComponent),
            multi: true,
        },
    ],
})
export class CheckInputComponent extends BaseInput implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public option: CheckInput<any>;
    @Input() public custom = true;
    @Input() public ignoreMinWidth = false;
    @Input() public direction = UiDirection.Vertical;
    @Input() public labelPosition = UiLabelPosition.Before;
    @Input() public labelTemplateRef?: TemplateRef<any>;
    @Input() public small?: boolean;

    @Input()
    @HostBinding('style.width')
    public width = '440px';

    public get checked(): boolean {
        return !!this.innerModel;
    }

    public get value(): unknown {
        return this.innerModel;
    }
    public innerModel: unknown;
    public optionLabelElement: string;

    public uiDirections = UiDirection;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private readonly translate: TranslateService,
        @Optional() private readonly formGroup: FormGroupDirective
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.errorStateMatcher = new MyErrorStateMatcher(this.formControlName);

        if (this.option?.label || this.option?.labelResource) {
            this.setOptionLabelElement();
            this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(this.setOptionLabelElement);
        }
    }

    public ngOnChanges({ option }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(option)) {
            if (this.option?.label || this.option?.labelResource) {
                this.setOptionLabelElement();
            }
        }
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: unknown): void {
        this.innerModel = value;
    }

    public onModelChange(): void {
        this.propagateChange(this.innerModel && this.option ? this.option.value : this.innerModel);
        this.propagateTouched();
    }

    /**
     * only show error when form is submitted
     */
    public isErrorState(): boolean {
        return this.errorStateMatcher.isErrorState(this.getAbstractControl() as UntypedFormControl, this.formGroup);
    }

    private setOptionLabelElement = (): void => {
        const optionLabel = this.option.label || (this.translate.instant(this.option.labelResource, this.option.labelParams) as string);
        const variant = this.option.variant || defaultVariant;
        this.optionLabelElement = `<${variant}>${optionLabel}</${variant}>`;
    };
}
