import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Inject, LOCALE_ID, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nKeyUtils, I18N_KEY_TYPE_MAP, NumberUtils, ObjectUtils } from '@smooved/core';
import { map, startWith } from 'rxjs/operators';
import { uiI18nKeyTypes } from '../i18n';
import { ErrorState, ErrorStatePipeOptions } from './error-state.interface';

@Pipe({
    name: 'errorState',
    pure: false,
})
export class ErrorStatePipe implements PipeTransform, OnDestroy {
    private asyncPipe: AsyncPipe;

    constructor(
        private readonly translateService: TranslateService,
        private readonly cdr: ChangeDetectorRef,
        @Inject(I18N_KEY_TYPE_MAP) private readonly i18nKeyTypeMap,
        @Inject(LOCALE_ID) private readonly localeId
    ) {}

    transform(state: ErrorState, options?: ErrorStatePipeOptions): string {
        const translation$ = this.translateService.onLangChange.pipe(
            startWith(this.translateService.currentLang),
            map((): string => {
                if (!state) return;
                const translateKey =
                    options?.messages?.[state.key] || I18nKeyUtils.map(this.i18nKeyTypeMap, uiI18nKeyTypes.FormValidation, state.key);
                //Show numbers in international format
                const formatNumber = (_, value): unknown =>
                    NumberUtils.isNumber(value) ? NumberUtils.internationalFormat(value, this.localeId) : value;
                ObjectUtils.forEach(state.value, formatNumber);
                return this.translateService.instant(translateKey, state.value) as string;
            })
        );

        this.dispose(); // Make sure there is no  existing AsyncPipe left
        this.asyncPipe = new AsyncPipe(this.cdr);
        return this.asyncPipe.transform(translation$);
    }

    private dispose(): void {
        this.asyncPipe?.ngOnDestroy();
    }

    public ngOnDestroy(): void {
        this.dispose();
    }
}
