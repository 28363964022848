/* eslint-disable */

import { DOCUMENT, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import nlBE from '@angular/common/locales/nl-BE';
import { APP_INITIALIZER, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatureScopeModule } from '@app/feature-scope/feature-scope.module';
import { getInitialMoveState } from '@app/move/state/move.reducer';
import { initialMoveStateMock } from '@app/move/state/move.reducer.mock';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { TranslationEffects } from '@app/translation/state/translation.effects';
import { translationReducer } from '@app/translation/state/translation.reducer';
import { UserModule } from '@app/user/user.module';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    AuthUser,
    CoreModule,
    ENV_FEATURE_FLAGS,
    FEATURE_TOGGLE_CONFIG,
    FeatureFlagConfig,
    FeatureScopeSandbox,
    FeatureToggleModule,
    I18N_KEY_TYPE_MAP,
    Language,
    Role,
    RootActionTypes,
    RootEffects,
    UrlUtils,
} from '@smooved/core';
import { BASE_URI_CONFIG, BaseUriConfig, EXTERNAL_INFLUENCER_URI_CONFIG, ExternalInfluencerUriConfig, MessagesModule } from '@smooved/ui';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { defaultLocale } from 'projects/core/src/lib/enums/locales.enum';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ActivityLogModule } from './activity-log/activity-log.module';
import { AppPreLoadingStrategy } from './app-pre-loading.strategy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthenticationSandbox } from './authentication/sandboxes/authentication.sandbox';
import { ClientModule } from './client/client.module';
import { AuthenticationGuard } from './guards/authentication/authentication.guard';
import { HeaderModule } from './header/header.module';
import { HttpModule } from './http/http.module';
import { UserLoginComponent } from './login/components/user-login/user-login.component';
import { MainContainerTemplateModule } from './main-container-template/main-container-template.module';
import { ModalModule } from './modal/modal.module';
import { MoveModule } from './move/move.module';
import { MoveSandbox } from './move/sandboxes/move.sandbox';
import { MoveService } from './move/services/move.service';
import { RealEstateAgentModule } from './real-estate-agent/real-estate-agent.module';
import { RealEstateGroupModule } from './real-estate-group/real-estate-group.module';
import { RealEstateGroupSandbox } from './real-estate-group/sandboxes/real-estate-group.sandbox';
import { RedirectComponent } from './redirect/components/redirect.component';
import { ReferrerType } from './referrer/enums/referrer-type.enum';
import { ReferrerSandbox } from './referrer/sandboxes/referrer.sandbox';
import { VersionCheckMessageComponent } from './shared/components/version-check-message/version-check-message.component';
import { i18nKeyTypeMap } from './shared/constants/i18n-key-type-map';
import { SharedModule } from './shared/shared.module';
import { ConsentModalComponent } from './start/components/consent-modal/consent-modal.component';
import { StartModule } from './start/start.module';
import { reducers, State } from './store/state';
import { HttpLoaderFactory, TranslationModule } from './translation/translation.module';
import { VersionCheckService } from './version-check.service';
import { LegalModule } from './wizard/legal/legal.module';
import { CustomMatPaginatorIntl } from '@app/material/custom-mat-paginator-intl.service';
import { IonicModule } from '@ionic/angular';

export function initMetaReducer(reducer) {
    return function (state: State, action: { type: string; payload: any }) {
        if (action.type === RootActionTypes.SetRootState) {
            return reducer(action.payload, action);
        }
        return reducer(state, action);
    };
}

const modules = [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
        metaReducers: [initMetaReducer],
    }),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
    }),
    NgxMaskModule.forRoot(),
    RealEstateAgentModule,
    LegalModule,
    ModalModule,
    UserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory(environment.cacheBuster),
            deps: [HttpClient],
        },
        compiler: {
            provide: TranslateCompiler,
            useClass: TranslateMessageFormatCompiler,
        },
    }),
    StoreModule.forFeature('translation', translationReducer),
    EffectsModule.forFeature([TranslationEffects]),
    FeatureScopeModule,
];

const components = [AppComponent, UserLoginComponent, VersionCheckMessageComponent];

const modals = [ConsentModalComponent];

const guards = [AuthenticationGuard];

export function getUrlSearchParam(url: URLSearchParams, key: string): string {
    return url.has(key) ? url.get(key) : undefined;
}

export function onAppInit(
    authenticationSandbox: AuthenticationSandbox,
    featureScopeSandbox: FeatureScopeSandbox,
    realEstateGroupSandbox: RealEstateGroupSandbox,
    moveService: MoveService,
    referrerSandbox: ReferrerSandbox,
    translateService: TranslateService,
    translationSandbox: TranslationSandbox,
    moveSandbox: MoveSandbox,
    platformId: object,
    document: Document
): () => Promise<any> {
    const moveConverter: (cvt: string, at?: string) => Observable<void> = (conversionToken: string, accessToken?: string) => {
        if (!conversionToken) return of(null);
        return moveService.convertMove(conversionToken, accessToken);
    };

    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            registerLocaleData(nlBE, 'nl-BE');

            // this language will be used as a fallback when a translation isn't found in the current language
            translateService.setDefaultLang(Language.NL);

            const accessToken = UrlUtils.getQueryParam('at');
            const loginToken = UrlUtils.getQueryParam('lt');
            const moveId = UrlUtils.getQueryParam('id');
            const conversionToken = UrlUtils.getQueryParam('cvt');

            const language = UrlUtils.getQueryParam('language') as Language;

            const usedLanguage = translationSandbox.setInitialLanguage(
                !!language && Object.values(Language).includes(language) ? language : null
            );

            if (isPlatformBrowser(platformId)) {
                document.documentElement.lang = usedLanguage;
            }

            moveConverter(conversionToken, accessToken)
                .pipe(
                    mergeMap(() => authenticationSandbox.authorization()),
                    mergeMap((authorizationResponse) => {
                        // if role is guest, but access token and move id is present, try to login first
                        if (
                            (authorizationResponse.role === Role.Guest || authorizationResponse.id !== moveId) &&
                            !!accessToken &&
                            !!moveId
                        ) {
                            return authenticationSandbox
                                .loginByAccessTokenAndId(accessToken, moveId)
                                .pipe(mergeMap((_) => authenticationSandbox.authorization()));
                        } else if (loginToken) {
                            return authenticationSandbox
                                .loginByLoginToken(loginToken)
                                .pipe(mergeMap((_) => authenticationSandbox.authorization()));
                        } else {
                            return of(authorizationResponse);
                        }
                    }),
                    tap((authorizationResponse) => {
                        authenticationSandbox.setAuthorization(authorizationResponse);
                    }),
                    mergeMap((authorizationResponse: AuthUser) => {
                        const referrerType = referrerSandbox.getReferrerTypeFromUrl();
                        const referrer = referrerSandbox.getReferrerFromUrl();

                        let query;

                        if (accessToken && moveId) {
                            query = {
                                accessToken,
                                id: moveId,
                            };
                        } else if (referrerType === ReferrerType.RealEstateAgent.toString() && referrer) {
                            query = {
                                realEstateAgentId: referrer,
                            };
                        } else {
                            query = {
                                id: authorizationResponse.id,
                            };
                        }

                        if (authorizationResponse.realEstateAgentId) {
                            return realEstateGroupSandbox.getRealEstateGroupForMe().pipe(
                                filter((x) => !!x),
                                tap((realEstateGroup) => realEstateGroupSandbox.setRealEstateGroup(realEstateGroup)),
                                map((_) => ({ accessToken, moveId, authorizationResponse }))
                            );
                        } else {
                            return realEstateGroupSandbox.getTheme(query).pipe(
                                filter((x) => !!x),
                                tap((theme) => realEstateGroupSandbox.setTheme(theme)),
                                map((_) => ({ accessToken, moveId, authorizationResponse }))
                            );
                        }
                    }),
                    mergeMap(({ accessToken, moveId, authorizationResponse }) => {
                        const initialState = environment.initialData ? initialMoveStateMock : getInitialMoveState();

                        if (authorizationResponse.role === Role.RealEstateAgent) {
                            moveSandbox.setMoveState({
                                ...initialState,
                                realEstateAgent: authorizationResponse.realEstateAgentId,
                            });

                            resolve(null);
                            return of(true);
                        } else if (
                            authorizationResponse.role === Role.Mover &&
                            !!authorizationResponse.email &&
                            !!authorizationResponse.id
                        ) {
                            return moveSandbox.get(authorizationResponse.id).pipe(
                                tap((move) => {
                                    moveSandbox.setMoveState(move);
                                    resolve(null);
                                }),
                                map((_) => true),
                                catchError((errorResponse) => {
                                    resolve(null);
                                    return throwError(errorResponse);
                                })
                            );
                        } else if (authorizationResponse.role === Role.Mover && !!authorizationResponse.email) {
                            // Get newest draft move when there is no move id defined in the authresponse
                            return moveSandbox.getNewestDraft().pipe(
                                tap((move) => {
                                    moveSandbox.setMoveStateIfDraft(move);
                                    resolve(null);
                                }),
                                map((_) => true),
                                catchError((errorResponse) => {
                                    resolve(null);
                                    return throwError(errorResponse);
                                })
                            );
                        } else if (authorizationResponse.role === Role.Guest && !!accessToken && !!moveId) {
                            return moveService.getByAccessTokenAndId(accessToken, moveId).pipe(
                                tap((move) => {
                                    moveSandbox.setMoveStateIfDraft(move);
                                    resolve(null);
                                }),
                                map((_) => true),
                                catchError((errorResponse) => {
                                    resolve(null);
                                    return throwError(errorResponse);
                                })
                            );
                        } else {
                            moveSandbox.setMoveState({
                                ...initialState,
                            });
                            resolve(null);
                            return of(true);
                        }
                    }),
                    tap((_) => referrerSandbox.init()),
                    catchError((errorResponse) => {
                        resolve(null);
                        return throwError(errorResponse);
                    })
                )
                .subscribe();
        });
    };
}

@NgModule({
    declarations: [...components, ...modals, RedirectComponent],
    imports: [
        SharedModule,
        AuthenticationModule,
        ...modules,
        TranslationModule,
        HttpModule,
        MoveModule,
        RealEstateAgentModule,
        RealEstateGroupModule,
        StartModule,
        HeaderModule,
        ActivityLogModule,
        ClientModule,
        CoreModule,
        MainContainerTemplateModule,
        MessagesModule,
        FeatureToggleModule,
    ],
    providers: [
        AppPreLoadingStrategy,
        CookieService,
        VersionCheckService,
        ...guards,
        {
            provide: APP_INITIALIZER,
            useFactory: onAppInit,
            deps: [
                AuthenticationSandbox,
                FeatureScopeSandbox,
                RealEstateGroupSandbox,
                MoveService,
                ReferrerSandbox,
                TranslateService,
                TranslationSandbox,
                MoveSandbox,
                PLATFORM_ID,
                DOCUMENT,
            ],
            multi: true,
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
        { provide: I18N_KEY_TYPE_MAP, useValue: i18nKeyTypeMap },
        { provide: LOCALE_ID, useValue: defaultLocale },
        { provide: BASE_URI_CONFIG, useValue: new BaseUriConfig(environment.apiUri) },
        { provide: ENV_FEATURE_FLAGS, useValue: new FeatureFlagConfig(environment.featureFlags) },
        { provide: FEATURE_TOGGLE_CONFIG, useValue: environment.featureToggle },
        {
            provide: EXTERNAL_INFLUENCER_URI_CONFIG,
            useValue: new ExternalInfluencerUriConfig(environment.apiUri, environment.houseMatchCompanyPageUri),
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
