<form [formGroup]="configForm">
    <app-select-input
        [options]="typeOptions"
        [label]="'TYPE' | i18nKeyTranslate: i18nKeyType.AdminCustomMessage"
        [hasMargin]="false"
        [hasMarginDouble]="true"
        [formControlName]="optionsFields.Type"
    ></app-select-input>
    <ui-dropdown-input
        [options]="contextOptions"
        [label]="'CONTEXT' | i18nKeyTranslate: i18nKeyType.AdminCustomMessage"
        [formControlName]="optionsFields.Context"
    ></ui-dropdown-input>
    <app-text-search-input
        [formControlName]="optionsFields.Template"
        [hasMargin]="false"
        [hasMarginDouble]="true"
        [options]="templateOptions$ | async"
        [label]="'TEMPLATE' | i18nKeyTranslate: i18nKeyType.AdminCustomMessage"
    ></app-text-search-input>
    <ui-dropdown-input
        [options]="languageOptions"
        [label]="'LANGUAGE.LABEL' | translate"
        [formControlName]="optionsFields.Language"
    ></ui-dropdown-input>
</form>
