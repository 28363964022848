import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { BadgeModule } from '../badge';
import { ButtonModule } from '../button/button.module';
import { DirectivesModule } from '../directives';
import { IconModule } from '../icon';
import { PipeModule } from '../pipes';
import { SvgModule } from '../svg';
import { AddressInputComponent } from './address-input/address-input.component';
import { AddressStreetInputComponent } from './address-street-input/address-street-input.component';
import { CheckInputComponent } from './check-input/check-input.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { DynamicAddressInputComponent } from './dynamic-address-input/dynamic-address-input.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { ErrorStatePipe } from './error-state.pipe';
import { LabelContainerComponent } from './label-container/label-container.component';
import { MeterReadingInputComponent } from './meter-reading-input/meter-reading-input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { RestrictInputDirective } from './restrict-input/restrict-input.directive';
import { ScoreInputComponent } from './score-input/score-input.component';
import { OptionDisplayDirective } from './searchable-dropdown-input/option-display.directive';
import { SearchableDropdownInputComponent } from './searchable-dropdown-input/searchable-dropdown-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TermsAndConditionsInputComponent } from './terms-and-conditions-input/terms-and-conditions-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TextSearchInputComponent } from './text-search-input/text-search-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';

const modules = [FormsModule, ReactiveFormsModule, MatSelectModule, MatSlideToggleModule, MatAutocompleteModule, MatIconModule];

const directives = [RestrictInputDirective, OptionDisplayDirective];

const components = [
    CheckInputComponent,
    EmailInputComponent,
    TextInputComponent,
    TextareaInputComponent,
    TextSearchInputComponent,
    LabelContainerComponent,
    DropdownInputComponent,
    SearchableDropdownInputComponent,
    SelectInputComponent,
    AddressStreetInputComponent,
    AddressInputComponent,
    NumberInputComponent,
    PhoneInputComponent,
    TermsAndConditionsInputComponent,
    ScoreInputComponent,
    DynamicAddressInputComponent,
    MeterReadingInputComponent,
    DateInputComponent,
];

const pipes = [ErrorStatePipe];

@NgModule({
    declarations: [...components, ...pipes, ...directives],
    imports: [
        ...modules,
        CommonModule,
        TranslateModule,
        MatInputModule,
        MatFormFieldModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        SvgModule,
        MatCheckboxModule,
        IconModule,
        ButtonModule,
        PipeModule,
        BadgeModule,
        NgxMaskModule.forRoot(),
        AutocompleteLibModule,
        DirectivesModule,
    ],
    exports: [...modules, ...components, ...pipes, ...directives],
})
export class UiFormModule {}
