import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ENV_FEATURE_FLAGS, FeatureFlagConfig } from '../configs/feature-flag-config';
import { FeatureFlag } from '../enums';
import { ObjectUtils } from '../utils';

interface FeatureFlagOptions {
    featureFlag: FeatureFlag;
    alwaysVisible?: boolean;
}

@Directive({ selector: '[featureFlag]' })
export class FeatureFlagDirective {
    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        @Inject(ENV_FEATURE_FLAGS) private readonly featureFlagConfig: FeatureFlagConfig
    ) {}

    @Input() set featureFlag(param: FeatureFlag | FeatureFlagOptions) {
        let featureFlag: FeatureFlag;
        let alwaysVisible = false;

        if (ObjectUtils.isObject(param)) {
            if ((param as FeatureFlagOptions).alwaysVisible) alwaysVisible = true;
            featureFlag = (param as FeatureFlagOptions).featureFlag;
        } else {
            featureFlag = param as FeatureFlag;
        }

        const condition = alwaysVisible || this.featureFlagConfig.envFeatureFlags[featureFlag];
        if (condition) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!condition && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
