import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { FeatureToggleModule } from './feature-toggle.module';
import { FEATURE_TOGGLE_CONFIG, FeatureToggleConfig } from './feature-toggle.config';

@Injectable({
    providedIn: FeatureToggleModule,
})
export class FeatureToggleGuard implements CanActivate {
    constructor(@Optional() @Inject(FEATURE_TOGGLE_CONFIG) public readonly config: FeatureToggleConfig, private readonly router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const feature = route.data?.feature;
        return this.continueOrGoToDashboard(this.config[feature]);
    }

    private continueOrGoToDashboard = (canActivate: boolean): boolean | UrlTree => canActivate || this.router.createUrlTree([]);
}
