import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationData } from '../../notification.interfaces';

@Component({
    selector: 'app-notification-error',
    template: ` <span translate [translateParams]="data.translateParams">{{ data.labelResource }}</span> `,
})
export class NotificationErrorComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData) {}
}
