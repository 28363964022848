import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureFlagDirective } from '@smooved/core';
import { UnknownIfEmptyDirective } from './unknown.directive';

const declarations = [FeatureFlagDirective, UnknownIfEmptyDirective];

@NgModule({
    declarations: declarations,
    exports: declarations,
    imports: [CommonModule],
})
export class DirectiveModule {}
