import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { State } from '@app/store/state';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { UiSandbox } from '@smooved/ui';
import { delay } from 'rxjs/operators';
import { hideLoadingOverlay, moveLoading, showLoadingOverlay } from '../state/ui.actions';
import { getLoadingOverlay, getMoveLoading, getPatchLoading } from '../state/ui.reducer';

@Injectable({
    providedIn: 'root',
})
export class AppUiSandbox extends UiSandbox {
    public featureFlags = environment.featureFlags;

    public loadingOverlay$ = this.store$.pipe(select(getLoadingOverlay), delay(0));
    public moveLoading$ = this.store$.pipe(select(getMoveLoading));
    public patchLoading$ = this.store$.pipe(select(getPatchLoading));

    constructor(private store$: Store<State>, protected breakpointObserver: BreakpointObserver, @Inject(PLATFORM_ID) platformId: object) {
        super(breakpointObserver, platformId);
    }

    public showLoadingOverlay(): void {
        this.store$.dispatch(showLoadingOverlay());
    }

    public hideLoadingOverlay(): void {
        this.store$.dispatch(hideLoadingOverlay());
    }

    public moveLoading(loading: boolean): void {
        this.store$.dispatch(moveLoading({ loading }));
    }
}
