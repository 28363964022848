<h6 class="u-color-muted u-margin-bottom-half">{{ title | translate }}</h6>
<form [formGroup]="form" class="__reviews-segments__form">
    <div
        [formArrayName]="segmentsForm.Segments"
        *ngFor="let segment of segments; index as i"
        class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between"
        [class.u-margin-bottom-half]="!showArcScore"
    >
        <app-check-input
            [custom]="false"
            [hasMargin]="false"
            width="auto"
            [formControlName]="i"
            [option]="segment.option"
            [id]="'segment-filter-' + i"
            [label]="'REVIEWS.SEGMENTS.INTERVIEWEE.' + segment.type.toUpperCase() | translate"
            direction="horizontal"
            labelPosition="after"
            #checked
        ></app-check-input>
        <app-arc-score
            *ngIf="showArcScore; else default"
            size="xxs"
            class="u-margin-bottom-half"
            [data]="segment.chartData"
            [value]="!!segment.data?.total ? segment.data.score : '-'"
            [disabled]="disabled || (!checked.value && filter.length)"
        ></app-arc-score>

        <ng-template #default>
            <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-around" [class.u-disabled]="disabled">
                <smvd-ui-svg-illustration
                    [svg]="svgIllustration.HouseMatchIcon"
                    [context]="uiContext.HouseMatchPrimary"
                    [style.width]="'16px'"
                    class="u-margin-right-half"
                ></smvd-ui-svg-illustration>
                <div
                    class="u-color-housematch-secondary u-font-family-cera-pro u-font-weight-semi-bold __housematch-score u-text-align-center"
                >
                    {{ !!segment.data?.total ? (segment.data.score | arcScoreValue) : '-' }}
                </div>
            </div>
        </ng-template>
    </div>
</form>
