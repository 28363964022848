import { InjectionToken } from '@angular/core';

export interface FeatureToggleConfig {
    [key: string]: boolean;
}

export const FEATURE_TOGGLE_CONFIG = new InjectionToken<FeatureToggleConfig>('FEATURE_TOGGLE_CONFIG');

export const defaultFeatureToggleConfig: FeatureToggleConfig = {};

export enum FeatureToggle {
    LocationProfile = 'locationProfile',
    LinkedReviews = 'linkedReviews',
    SmoovedCompanion = 'smoovedCompanion',
}
