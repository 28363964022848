<app-todo
    *ngIf="move"
    [state]="move.track.leaverAdmin.regularizationBillRequestedByAdmin"
    [label]="'DETAIL.ENERGY_STOP.REGULARIZATION_BILL_REQUESTED' | i18nKeyTranslate: i18nKeys.AdminDashboard"
>
    <app-todo-template *todoStateUnchecked>
        <a
            *ngIf="move.moveStates.energyStopComplete"
            app-button
            [context]="uiContext.Secondary"
            [size]="buttonSize.Sm"
            (click)="requestBill()"
        >
            {{ 'REQUEST' | translate }}
        </a>
    </app-todo-template>

    <app-todo-template *todoStateChecked>
        <a
            app-button
            [context]="uiContext.Secondary"
            [appearance]="buttonAppearance.Stroked"
            [size]="buttonSize.Sm"
            (click)="requestBill()"
        >
            {{ 'RESEND' | translate }}
        </a>
        <a app-button [context]="uiContext.Secondary" [size]="buttonSize.Sm" (click)="downloadEDS()">{{ 'DOWNLOAD' | translate }}</a>

        <span id="linkedMoveDate">{{
            move?.linkedMove?.energyDocumentsLeavingAddressByAdminAudit?.lastModifiedOn ||
                move?.linkedMove?.energyDocumentsLeavingAddressByAdminAudit?.createdOn | formatDate
        }}</span>
    </app-todo-template>
</app-todo>
