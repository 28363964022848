import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nKeyModule, MetaModule, PipeModule as CorePipeModule } from '@smooved/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { AccordionModule } from '../accordion';
import { ButtonModule } from '../button';
import { CardsModule } from '../cards';
import { ChartsModule } from '../charts';
import { UiFormModule } from '../form';
import { IconModule } from '../icon';
import { LanguageModule } from '../language';
import { LoadingModule } from '../loading';
import { MenuModule } from '../menu';
import { UiModalModule } from '../modal';
import { PipeModule } from '../pipes';
import { RealEstateGroupModule } from '../real-estate-group';
import { ReviewsModule } from '../reviews';
import { SkeletonModule } from '../skeleton';
import { SvgModule } from '../svg';
import { TooltipModule } from '../tooltip';
import { CompanyPageProvidersModule } from './company-page-providers.module';
import {
    AboutShortComponent,
    CompanyCtaBoxComponent,
    ContactInfoComponent,
    HeaderBoxComponent,
    HeaderBoxWidgetComponent,
    MakeAppointmentFormComponent,
    OfficeComponent,
    RealEstateGroupActivationStageComponent,
    RealEstateGroupKpiComponent,
    SingleReviewComponent,
} from './components';
import { HeaderBoxMultiSelectComponent } from './components/header-box-multi-select/header-box-multi-select.component';
import { MatchExternalScoreComponent } from './components/match-external-score/match-external-score.component';
import { MatchScoreComponent } from './components/match-score/match-score.component';
import { MatchStarScoreComponent } from './components/match-star-score/match-star-score.component';
import { RealEstateGroupBoxComponent } from './components/real-estate-group-box/real-estate-group-box.component';
import { VerifiedBadgeComponent } from './components/verified-badge/verified-badge.component';
import { AboutContainer, CompanyPageTemplateContainer, OfficesAsideContainer, OfficesContainer, ReviewsContainer } from './containers';
import { InfluencerScoreModal, MakeAppointmentModal, RealEstateGroupAppointmentDetailModal } from './modals';
import { RealEstateAgentClusterPipe } from './pipes';
import { StarsScorePipe } from './pipes/stars-score.pipe';
import { VerifiedBadgePipe } from './pipes/verified-badge.pipe';
import { CompanyPageResolver } from './resolvers';
import { FeatureScopeUiModule } from '@smooved/core';

const components = [
    HeaderBoxWidgetComponent,
    HeaderBoxComponent,
    HeaderBoxMultiSelectComponent,
    RealEstateGroupKpiComponent,
    RealEstateGroupBoxComponent,
    RealEstateGroupActivationStageComponent,
    CompanyCtaBoxComponent,
    AboutShortComponent,
    ContactInfoComponent,
    OfficeComponent,
    MakeAppointmentFormComponent,
    VerifiedBadgeComponent,
    SingleReviewComponent,
    MatchStarScoreComponent,
    MatchExternalScoreComponent,
    MatchScoreComponent,
];
const containers = [CompanyPageTemplateContainer, ReviewsContainer, AboutContainer, OfficesContainer, OfficesAsideContainer];
const modals = [InfluencerScoreModal, MakeAppointmentModal, RealEstateGroupAppointmentDetailModal];
const pipes = [RealEstateAgentClusterPipe, VerifiedBadgePipe, StarsScorePipe];

@NgModule({
    declarations: [...components, ...containers, ...modals, ...pipes],
    exports: [...components, ...containers, ...pipes],
    imports: [
        RealEstateGroupModule,
        CommonModule,
        TranslateModule,
        MenuModule,
        RouterModule,
        ButtonModule,
        LanguageModule,
        IconModule,
        SkeletonModule,
        I18nKeyModule,
        ChartsModule,
        CorePipeModule,
        PipeModule,
        ReviewsModule,
        AccordionModule,
        LoadingModule,
        GoogleMapsModule,
        CardsModule,
        UiModalModule,
        UiFormModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        CompanyPageProvidersModule,
        TooltipModule,
        SvgModule,
        FeatureScopeUiModule,
        MetaModule,
        BrowserTransferStateModule,
        ReviewsModule,
        LocalizeRouterModule,
    ],
})
export class CompanyPageModule {
    public static forRoot(): ModuleWithProviders<CompanyPageModule> {
        return {
            ngModule: CompanyPageModule,
            providers: [CompanyPageResolver],
        };
    }
}
