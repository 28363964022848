<form [formGroup]="form" class="__reviews-buckets__form">
    <ng-container formArrayName="buckets" *ngFor="let bucket of bucketList; index as i; trackBy: trackByBucket">
        <app-check-input
            [custom]="false"
            width="auto"
            [formControlName]="i"
            [option]="bucket.option"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [label]="bucket.label | i18nKey : i18nKeyType.ReviewBucket | translate"
            [labelTemplateRef]="type === reviewBucketsTypes.Stars ? stars : ''"
            direction="horizontal"
            labelPosition="after"
            #checked
        ></app-check-input>
        <app-chart-percentage
            [showTooltip]="false"
            [total]="bucket.total"
            [value]="bucket.percentage"
            [context]="context"
            class="u-margin-bottom-none"
            [disabled]="!checked.value && filter.length"
        ></app-chart-percentage>

        <ng-template #stars>
            <ui-star-score [score]="bucketsToStarsMap[bucket.option.value]" class="u-margin-top-xs"></ui-star-score>
        </ng-template>
    </ng-container>
</form>
