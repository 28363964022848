import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { SvgIllustration } from '@smooved/ui';

@Component({
    selector: 'app-table-column-detail-arrow',
    template: `
        <!-- Detail Definition -->
        <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef class="__cell __cell--detail"></th>
            <td mat-cell valign="top" *matCellDef="let row" class="__cell u-text-align-center">
                <app-svg-illustration class="__icon u-margin-top-double" [svg]="chevronRightSvg"></app-svg-illustration>
            </td>
        </ng-container>
    `,
    styleUrls: ['./table-column-detail-arrow.component.scss'],
})
export class TableColumnDetailArrowComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;
    public chevronRightSvg = SvgIllustration.ChevronRight;

    constructor(
        @Optional() public table: MatTable<any>,
        public uiSandbox: AppUiSandbox,
        private cdRef: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }
}
